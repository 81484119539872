import React, { useContext, useEffect, useRef } from "react"
import { PageProps, graphql, navigate } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { OfferDetailTemplateProps } from "./templates"
import { Offer } from "../global"
import { chunkArray, reformattedOfferType } from "../helpers"
import { OfferDetailHero } from "../components/organisms/OfferDetailTemplate/OfferDetailHero"
import { OfferDetailCard } from "../components/organisms/OfferDetailTemplate/OfferDetailCard"
import { OfferDetailDescription } from "../components/organisms/OfferDetailTemplate/OfferDetailDescription"
import { OfferDetailOtherOffers } from "../components/organisms/OfferDetailTemplate/OfferDetailOtherOffers"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { LanguageContext } from "../contexts/Language"
import { LocationContext } from "../contexts/Location"
import useDealers from "../hooks/useDealers"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import { useTealiumContext } from "../contexts/Tealium"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import useOffersData from "../hooks/useOffersData"
import { SEO } from "../components/atoms/SEO"
import tw from "twin.macro"
import { Back } from "../components/atoms/Back"

/**
 * Template used to display details of each individual offer.
 * @author Tyler
 *
 * @param { name: string, currentYear: number} series Series data
 * @param {IGatsbyImageData} offerImage.asset.gatsbyImageData Hero vehicle image data
 * @param {string} description Description text of the offer
 * @param { name: string } includedModels All models the offer applies to
 * @param { string } bullet An expanded description
 * @param { string } disclaimer Terms & Conditions
 *
 * @todo Create an offers context, a lot of this offers stuff is across several templates/pages/components
 */

const OfferDetailTemplate: React.FC<PageProps & OfferDetailTemplateProps> = ({
  data,
  location,
}) => {
  //~~~~~~~~~~ Desctructure graphQL data ~~~~~~~~~~
  const { page, offer } = data

  // Page data
  const {
    heroBackgroundImageDesktop,
    heroBackgroundImageMobile,
    moreAboutImage,
    otherOffersBackground,
  } = page
  // Offer data
  const {
    isExpiringSoon,
    series,
    offerImage,
    description,
    descriptionES,
    includedModels,
    bullet,
    bulletES,
    disclaimer,
    disclaimerES,
    extraDisclaimer,
    extraDisclaimerES,
    cardLabel,
    cardLabelES,
    cardTitle,
    cardTitleES,
    endDate,
    monthlyPayment,
    duration,
    dueAtSigningAmount,
    termRates,
    cashAmount,
    type,
    year,
  } = offer

  const offers = useOffersData()
  const { language, _ } = useContext(LanguageContext)

  // APR data
  const { rate, term } = termRates[0] || {}
  // Series data
  const { name, msrp, mpgCity, mpgHighway, slug, range } = series
  // Image data
  let heroImage = offerImage

  // if (heroImage === undefined) {
  //   //no image was supplied for the offer so we should pick a model image
  //   // the included models array is order from least expensive to most expensive so taking the last model to use a better looking model
  //   heroImage =
  //     includedModels[includedModels.length - 1].colors[0].exteriorImages[2]
  //       .asset.gatsbyImageData
  // }

  // Offers filtered by series of current offer page
  const seriesOffers = offers?.filter(
    (offer: Offer) =>
      offer?.series?.name.toLocaleLowerCase() ===
        name.toLocaleLowerCase() + " hybrid" ||
      offer?.series?.name.toLocaleLowerCase() === name.toLocaleLowerCase()
  )

  // Total offers that apply to series
  const offerCount = seriesOffers?.length
  // Offers chunked into groups of 3 for carousel
  const carouselSlides = chunkArray(seriesOffers, 3)

  /* Tealium - Start */
  const { handleTealView } = useTealiumView()
  const { tealPageData } = useTealiumContext()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()
  const offerType: string = reformattedOfferType(type)

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* Fire only on inital page load, and not every time dealers is updated */
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_name: "offers detail",
        tealium_event: "offer_details",
        page_type: "offers",
        offer_type: `offers | offers detail | ${offerType} | ${cardTitle}`,
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: "offers detail",
        tealium_event: "offer_details",
        page_type: "offers",
        offer_type: `offers | offers detail | ${offerType} | ${cardTitle}`,
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  /* Tealium - End */

  const offerDescription = language === "es" ? descriptionES : description
  const offerDisclaimer = language === "es" ? disclaimerES : disclaimer
  const offerExtraDisclaimer =
    language === "es" ? extraDisclaimerES : extraDisclaimer

  let offerBullet = language === "es" ? bulletES : bullet
  // Removing TFS from Lease Cash offer type
  if (offerBullet?.includes("TFS Lease Cash")) {
    offerBullet = offerBullet?.replaceAll("TFS Lease Cash", "Lease Cash")
  }
  if (offerBullet?.includes("TFS de arrendamiento")) {
    offerBullet = offerBullet?.replaceAll(
      "TFS de arrendamiento",
      "de arrendamiento"
    )
  }
  // Removing TFS from Finance Cash offer type
  if (offerBullet?.includes("TFS Finance Cash")) {
    offerBullet = offerBullet?.replaceAll("TFS Finance Cash", "Finance Cash")
  }
  if (offerBullet?.includes("TFS de arrendamiento")) {
    offerBullet = offerBullet?.replaceAll(
      "TFS de arrendamiento",
      "de arrendamiento"
    )
  }
  const offerCardTitle = language === "es" ? cardTitleES : cardTitle
  const offerCardLabel = language === "es" ? cardLabelES : cardLabel
  const titleValue =
    `${year}` + " " + `${name}` + " " + `${type}` + " " + `${description}`
  const truncatedTitle =
    titleValue.length > 60 ? titleValue.substring(0, 60) + "..." : titleValue

  const goBack = () => {
    if (typeof window !== "undefined") {
      const referrer = document.referrer
      let backLink

      if (!referrer || !referrer.includes(window.location.host)) {
        backLink = "/offers"
      } else {
        const referrerPath = new URL(referrer).pathname
        backLink = referrerPath
      }

      navigate(backLink)
    }
  }

  // Extract the referrer path
  let url = new URL(
    typeof window !== "undefined"
      ? document.referrer || "https://exploresetoyota.com"
      : "https://exploresetoyota.com"
  )
  let referrerPath = url.pathname.replace(/^\//, "").replace(/\/$/, "")

  // Extract the series name without 'hybrid'
  let seriesNameWithoutHybrid = offer.series?.name
    .toLowerCase()
    .replace("hybrid", "")
    .trim()

  // Set the default back button text
  let backButtonText = _("Back to Offers")
  // Update the back button text based on the referrer path
  if (referrerPath.includes("inventory")) {
    backButtonText = _("Back to Vehicle")
  } else if (referrerPath.includes(seriesNameWithoutHybrid)) {
    let seriesName = offer.series?.name.replace("Hybrid", "").trim()
    backButtonText = _("Back to") + " " + seriesName + " " + _("Offers")
  }
  if (referrerPath.startsWith("offers/" + offer.series?.name.toLowerCase())) {
    let seriesName = offer.series?.name.replace("Hybrid", "").trim()
    backButtonText = _("Back to") + " " + seriesName + " " + _("Offers")
  }
  if (referrerPath.includes("dealers")) {
    backButtonText = _("Back to Dealer")
  }

  const schema = `
  {
    "@context": "http://schema.org",
    "@type": "Offer",
    "name": "Toyota ${offer?.series?.name} Offers",
    "description": "Explore this special offer on Toyota vehicles. Limited-time promotion.",
    "url": "https://exploresetoyota.com/offers/${offer?._id}/"
    "availability": "http://schema.org/InStock",
    "validFrom": "${offer?.startDate}",
    "validThrough": "${offer?.endDate}",
    "seller": {
    "@type": "AutoDealer",
    "name": "Toyota Dealership"
    },
    "image": "https://cdn.sanity.io/images/90qxor39/production/9bf3dd539c9eac39c1a4b2465e19d7191702222e-500x415.jpg",
    "itemOffered": {
    "@type": "Offers",
    "name": "current offers",
    "description": "View current offers"
    }
    }
  `

  return (
    <Layout
      tealium={{
        label: "offerDetailsPage",
        extraData: {
          offer_type: `offers | offers detail | ${offerType} | ${cardTitle}`,
        },
      }}
    >
      <SEO url={location.href} title={truncatedTitle} schema={schema} />
      <div css={[tw`my-5 flex flex-col content-center mx-auto max-w-desktop`]}>
        <div css={[tw`text-toyotaRed cursor-pointer pl-6`, tw`sm:(pl-10)`]}>
          <Back link="#" onClick={goBack} cta={backButtonText} />
        </div>
      </div>
      <OfferDetailHero
        heroBackgroundDesktop={heroBackgroundImageDesktop?.image?.asset?.url}
        heroBackgroundMobile={heroBackgroundImageMobile?.image?.asset?.url}
        heroImage={heroImage}
        name={name}
        type={type}
      />
      <OfferDetailCard
        cardType={cardLabel}
        cardLabel={offerCardLabel}
        cardTitle={offerCardTitle}
        isExpiringSoon={isExpiringSoon}
        endDate={endDate}
        currentYear={year}
        name={name}
        monthlyPayment={monthlyPayment}
        duration={duration}
        dueAtSigningAmount={dueAtSigningAmount}
        msrp={msrp}
        mpgCity={mpgCity}
        mpgHighway={mpgHighway}
        range={range}
        offerCount={offerCount}
        rate={rate}
        term={term}
        cashAmount={cashAmount}
        type={type}
        slug={slug}
        extraCashAmount={offer?.extraCashAmount}
        extraCashLabel={offer?.extraCashLabel}
      />
      <OfferDetailDescription
        description={offerDescription}
        includedModels={includedModels}
        bullet={offerBullet}
        disclaimer={offerDisclaimer}
        extraDisclaimer={offerExtraDisclaimer}
        name={name}
        moreAboutImage={moreAboutImage}
      />
      <DealersNearYou
        type="default"
        ctaAnalyticsId={"Contact Dealer"}
        offerType={`offers | offers detail | ${offerType} | ${cardTitle}`}
      />
      <OfferDetailOtherOffers
        offers={carouselSlides}
        otherOffersBackground={otherOffersBackground?.image}
      />
    </Layout>
  )
}

export const query = graphql`
  query OfferDetailTemplateQuery($id: String) {
    page: sanityPageOfferDetail {
      title
      heroBackgroundImageDesktop {
        alt
        image {
          asset {
            url
          }
        }
      }
      heroBackgroundImageMobile {
        alt
        image {
          asset {
            url
          }
        }
      }
      moreAboutImage {
        ...SanityImageData
      }
      otherOffersBackground {
        ...SanityImageData
      }
    }
    offer: sanityOffer(_id: { eq: $id }) {
      ...Offer
    }
  }
`

export default OfferDetailTemplate
