import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { OfferDetailOtherOffersProps } from "./OfferDetailOtherOffers.d"
import { Image } from "../../../atoms/Image"
import { OfferCard } from "../../../molecules/OfferCard"
import { Offer } from "../../../../global"
import Icon from "../../../atoms/Icon"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../../contexts/Language"

/**
 * The "other offers" section of the OfferDetail page.
 * @author Tyler
 *
 * @param {Object} offers Offers object containing subarrays of up to 3 offers per
 * @returns <section></section>
 *
 * @todo Update with new carousel component when it's created
 */

const OfferDetailOtherOffers: React.FC<OfferDetailOtherOffersProps> = ({
  offers,
  otherOffersBackground,
}) => {
  // Controls active carousel item
  const [carouselData, setCarouselData] = useState(0)
  // Used to detect end of carousel items
  const offersLength = offers?.length - 1 ?? null
  // Offers grouped in 3s flattened into one array
  const allOffers = offers?.flat() ?? {}

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { language, _ } = useContext(LanguageContext)

  return (
    <section
      css={[
        tw`relative flex items-end min-h-[33rem] bg-black`,
        tw`md:(min-h-[36rem])`,
        tw`lg:(min-h-[40rem])`,
        tw`xl:(min-h-0)`,
      ]}
      aria-label="Other Offers Section"
    >
      {otherOffersBackground && (
        <Image imageData={otherOffersBackground} css={[tw`w-full`]} />
      )}
      <section
        css={[
          tw`w-full`,
          tw`lg:(px-[4.5rem])`,
          // If the background image is defined in Sanity, apply absolute styling, otherwise don't
          // This keeps the container from collapsing in the absence of the background image
          otherOffersBackground && tw`absolute top-0 left-0 `,
        ]}
        aria-label="Other Offers Section"
      >
        <h3
          css={[
            tw`text-white text-2xl tracking-[3px] font-light ml-8 my-12`,
            tw`lg:(text-3xl tracking-[3.75px] my-[3.75rem])`,
          ]}
        >
          {_("Other Offers")}
        </h3>
        {/* Mobile component */}
        <section
          css={[
            tw`flex gap-5 w-full overflow-x-scroll pt-3 pl-8 scrollbar-hide`,
            tw`lg:(hidden)`,
          ]}
        >
          {allOffers?.map((node: Offer, i: number) => (
            <OfferCard
              offer={node}
              cardType={node?.cardLabel}
              cardLabel={
                language === "es" ? node?.cardLabelES : node?.cardLabel
              }
              css={[tw`min-w-[18.125rem]`]}
              analyticsId={`offer details:other offers:${i + 1}`}
            />
          ))}
        </section>
        {/* Desktop component */}
        <section
          css={[
            tw`hidden justify-center items-center gap-5`,
            tw`lg:(flex)`,
            tw`xl:(gap-10)`,
          ]}
        >
          <button
            onClick={() => {
              carouselData === 0
                ? setCarouselData(offersLength)
                : setCarouselData(carouselData - 1),
                trackTealEvent({
                  tealium_event: "carousel_click",
                  carousel_action: "left arrow",
                })
            }}
            analytics-id="left arrow:other offers:other offers"
          >
            <Icon.Chevron direction="left" color="#b2b2b2" css={[tw`h-14`]} />
          </button>
          <div
            css={[
              tw`flex justify-center gap-5 min-w-full`,
              tw`lg:(min-w-[904px])`,
            ]}
          >
            {offers[carouselData]?.map((node: Offer, i: number) => (
              <OfferCard
                offer={node}
                cardType={node?.cardLabel}
                cardLabel={
                  language === "es" ? node?.cardLabelES : node?.cardLabel
                }
                css={[tw`transition-all duration-500`]}
                analyticsId={`offer details:other offers:${i + 1}`}
              />
            ))}
          </div>
          <button
            onClick={() => {
              carouselData === offersLength
                ? setCarouselData(0)
                : setCarouselData(carouselData + 1),
                trackTealEvent({
                  tealium_event: "carousel_click",
                  carousel_action: "right arrow",
                })
            }}
            analytics-id="right arrow:other offers:other offers"
          >
            <Icon.Chevron direction="right" color="#b2b2b2" css={[tw`h-14`]} />
          </button>
        </section>
      </section>
    </section>
  )
}

export default OfferDetailOtherOffers
