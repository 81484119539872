import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { TWColor } from "../../../../global"
import {
  getSeriesMpgDisclaimerCode,
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
  toUsd,
  vehicleCategory,
} from "../../../../helpers"
import { Badge } from "../../../atoms/Badge"
import { Link } from "../../../atoms/Link"
import Icon from "../../../atoms/Icon"
import { OfferDetailCardProps } from "./OfferDetailCard.d"
import { getTWColorHex } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { ShareButton } from "../../../atoms/ShareButton"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { LocationContext } from "../../../../contexts/Location"
import { InventoryClient } from "../../../../clients/InventoryClient"
import { LocalInventoryLinks } from "../../../molecules/LocalInventoryLinks"
import { generateInventoryLink } from "../../../../helpers"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import {
  electricSeriesNames,
  useElectricPluginSeriesNames,
} from "../../../../hooks/useElectricPluginSeriesNames"

/**
 * Card displaying key details about the offer
 * @author Tyler
 *
 * @param {string} cardLabel Type of card, dispalyed in badge on top of the offer card
 * @param {string} endDate Date the offer expires
 * @param {number} expIn Number of days until the offer expires
 * @param {number} currentYear Current year
 * @param {string} name Name of the series the offer applies to
 * @param {number} monthlyPayment Monthly payment
 * @param {number} duration Number of months the lease is for
 * @param {number} dueAtSigningAmount Downpayment due when signing
 * @param {number} msrp Base cost of vehicle
 * @param {number} mpgCity Mileage in the city
 * @param {number} mpgHighway Mileage on the highway
 * @param {number} offerCount Number of offers corresponding to current series
 * @returns <section></section>
 *
 * @todo Save to my offers functionality
 */

const OfferDetailCard: React.FC<OfferDetailCardProps> = ({
  cardType,
  cardLabel,
  cardTitle,
  endDate,
  isExpiringSoon,
  currentYear,
  name,
  monthlyPayment,
  duration,
  dueAtSigningAmount,
  msrp,
  mpgCity,
  mpgHighway,
  offerCount,
  rate,
  term,
  cashAmount,
  type,
  slug,
  range,
  extraCashAmount,
  extraCashLabel,
}) => {
  const bgGradient = `background: linear-gradient(rgba(237, 240, 245), rgba(255, 255, 255) 30%)`

  const [{ dealers, city }] = useContext(LocationContext)
  const [{ disclaimers }, dispatch] = useContext(DisclaimersContext)
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(
    name,
    currentYear?.toString()
  )
  const pluginSeriesNames = useElectricPluginSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    name?.toLocaleLowerCase()
  )
  const electricName = electricSeriesNames()
  const isAllElectric = electricName.includes(name?.toLocaleLowerCase())

  let seriesPath = normalizeSeriesNameForLink(name)
  if (name?.toLowerCase()?.includes("hybrid")) {
    seriesPath += "?hybrid=true"
  }

  // Controls styling specific to lease card types
  const isLease = cardType === "Lease"

  // Controls styling specific to lease and cash card types
  const isAPRorCash = cardType === "APR" || cardType === "Cash"

  // Set badge color based on card type
  let colorBasedOnType: TWColor
  switch (cardType) {
    case "APR":
      colorBasedOnType = "green"
      break
    case "Lease":
      colorBasedOnType = "blue"
      break
    case "Cash":
      colorBasedOnType = "red-400"
      break
    case "Lease Cash":
      colorBasedOnType = "blue"
      break
    case "Finance Cash":
      colorBasedOnType = "green"
      break
    default:
      colorBasedOnType = "red-400"
      break
  }
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
    const getCount = async () => {
      if (dealerIds?.length > 0) {
        try {
          setInventoryCount("...")
          const { count } = await InventoryClient.getCount(name, {
            dealer: dealerIds,
            series: name.toLowerCase(),
          })
          setInventoryCount(count)
        } catch (error) {
          setInventoryCount(error)
        }
      }
    }
    getCount()
  }, [dealers])

  let seriesOffersPath = normalizeSeriesNameForLink(name)
  //Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { _ } = useContext(LanguageContext)

  return (
    <section
      css={[bgGradient, tw`pt-5 px-0`, tw`md:(px-10)`, tw`lg:(flex)`]}
      aria-label="Offer Details Card"
    >
      <div
        css={[
          tw`max-w-7xl w-full`,
          tw`md:(flex justify-center)`,
          tw`lg:(flex mx-auto justify-center)`,
        ]}
      >
        <article
          css={[
            tw`flex flex-col justify-start items-center bg-white max-w-[61rem] w-full px-3 pb-6 rounded-lg shadow-3 overflow-visible`,
            tw`lg:(px-6)`,
            tw`xl:(px-14)`,
            isAPRorCash && tw`max-w-4xl`,
          ]}
        >
          <Badge
            css={[tw`-mt-3 text-xs leading-3`, tw`md:(-mt-3)`]}
            color={colorBasedOnType}
          >
            {/* Change "APR" to "Finance" */}
            {cardType === "APR" ? _("Finance") : cardLabel}
          </Badge>
          <div
            css={[
              tw`flex items-center mt-4 text-sm leading-3`,
              isExpiringSoon && tw`text-red-400`,
            ]}
          >
            <span>
              {_("Expires")}: {endDate}
            </span>
            {isExpiringSoon && (
              <Icon.Warning color="red-400" css={[tw`w-4 ml-1`]} />
            )}
          </div>
          <section
            css={[
              tw`flex flex-col mt-4`,
              tw`xl:(flex-row justify-between items-center w-full)`,
            ]}
          >
            {/* Series, msrp, and mpg container */}
            <section css={[tw`flex flex-col items-center`]}>
              <div css={[tw`xl:(min-w-[300px])`]}>
                <p
                  css={[
                    tw`text-4xl font-semibold text-center`,
                    tw`xl:(text-5xl)`,
                  ]}
                >
                  {language === "en"
                    ? currentYear + " " + name
                    : name + " " + currentYear}
                </p>
                <Link
                  animated
                  animatedThin
                  to={`/${seriesPath}`}
                  css={tw`mb-2 text-xs xl:(ml-4 mt-2)`}
                >
                  {_("View Models")}{" "}
                  <Icon.Chevron
                    color="red-400"
                    css={[tw`w-2 ml-0.5 mb-0.5 inline-block`]}
                  />
                </Link>
              </div>
              <div
                css={[
                  tw`flex justify-center gap-7 w-full mt-4 xl:mt-0`,
                  tw`xl:(justify-center)`,
                ]}
              >
                <div css={[tw`flex flex-col items-center`]}>
                  <div css={[tw`text-2xl whitespace-nowrap`]}>
                    ${toUsd(msrp)}
                    {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                      dispatch(toggleDisclaimersModal(code))
                    )}
                  </div>
                  <div css={[tw`text-[0.625rem] text-[#58595b] italic`]}>
                    {_("Starting MSRP")}
                  </div>
                </div>
                <div css={[tw`flex flex-col items-center`]}>
                  <div css={[tw`text-2xl whitespace-nowrap`]}>
                    {isAllElectric ? (
                      <span>{range} mi</span>
                    ) : (
                      <>
                        {mpgCity}/{mpgHighway}
                      </>
                    )}
                    {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
                      dispatch(toggleDisclaimersModal(code))
                    )}
                  </div>
                  <div css={[tw`text-[0.625rem] text-[#58595b] italic`]}>
                    {isElectricPluginSeries && _("estimated MPG/MPGe")}
                    {!isElectricPluginSeries &&
                      !isAllElectric &&
                      _("estimated MPG")}
                    {isAllElectric && "Range"}
                  </div>
                </div>
              </div>
            </section>
            <section>
              {/* Per month, duration, and dueAtSigning container */}
              <div
                css={[
                  tw`flex justify-center w-full my-10`,
                  tw`lg:(my-0)`,
                  isLease && tw`lg:(justify-end)`,
                ]}
              >
                {/* Cash Amount */}
                {cashAmount && (
                  <div css={[tw`flex flex-col items-center gap-5`]}>
                    <div
                      css={[
                        tw`flex items-start`,
                        tw`lg:(text-[3.375rem])`,
                        `color: ${getTWColorHex(colorBasedOnType)}`,
                      ]}
                    >
                      <span
                        css={[
                          tw`text-lg font-semibold`,
                          tw`lg:(text-3xl)`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        $
                      </span>
                      <span
                        css={[
                          tw`text-4xl font-semibold`,
                          tw`lg:(text-[3.375rem])`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        {toUsd(cashAmount)}
                      </span>
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {type} {cardType === "Cash" && _("Rebate")}
                    </div>
                  </div>
                )}
                {/* Rate */}
                {rate !== undefined && (
                  <div
                    css={[
                      tw`flex flex-col items-center gap-5 pr-6 border-r border-black`,
                      tw`lg:(pr-6)`,
                    ]}
                  >
                    <div css={[tw`flex items-start`]}>
                      <span
                        css={[
                          tw`text-4xl font-semibold`,
                          tw`lg:(text-[3.375rem])`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        {rate}
                      </span>
                      <span
                        css={[
                          tw`text-lg font-semibold`,
                          tw`lg:(text-3xl)`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        %
                      </span>
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold uppercase`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {_("APR")}
                    </div>
                  </div>
                )}
                {/* Term */}
                {term && (
                  <div
                    css={[
                      tw`flex flex-col items-center gap-5 pl-6`,
                      tw`lg:(pl-6)`,
                    ]}
                  >
                    <div
                      css={[
                        tw`flex items-start text-4xl font-semibold`,
                        tw`lg:(text-[3.375rem])`,
                        `color: ${getTWColorHex(colorBasedOnType)}`,
                      ]}
                    >
                      {term}
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold uppercase`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {_("MONTHS")}
                    </div>
                  </div>
                )}
                {/* Per month */}
                {monthlyPayment && (
                  <div
                    css={[
                      tw`flex flex-col items-center gap-5 pr-6`,
                      tw`lg:(pr-6)`,
                    ]}
                  >
                    <div css={[tw`flex items-start`]}>
                      <span
                        css={[
                          tw`text-lg font-semibold`,
                          tw`lg:(text-3xl)`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        $
                      </span>
                      <span
                        css={[
                          tw`text-4xl font-semibold`,
                          tw`lg:(text-[3.375rem])`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        {monthlyPayment}
                      </span>
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold uppercase`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {_("PER MONTH")}
                    </div>
                  </div>
                )}
                {/* Duration */}
                {duration && (
                  <div
                    css={[
                      tw`flex flex-col items-center gap-5 px-6 border-l border-r border-black`,
                      tw`lg:(px-6)`,
                    ]}
                  >
                    <div
                      css={[
                        tw`flex items-start text-4xl font-semibold`,
                        tw`lg:(text-[3.375rem])`,
                        `color: ${getTWColorHex(colorBasedOnType)}`,
                      ]}
                    >
                      {duration}
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold uppercase`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {_("MONTHS")}
                    </div>
                  </div>
                )}
                {/* Due at signing */}
                {dueAtSigningAmount && (
                  <div
                    css={[
                      tw`flex flex-col items-center gap-5 pl-6`,
                      tw`lg:(pl-6)`,
                    ]}
                  >
                    <div css={[tw`flex items-start`]}>
                      <span
                        css={[
                          tw`text-lg font-semibold`,
                          tw`lg:(text-3xl)`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        $
                      </span>
                      <span
                        css={[
                          tw`text-4xl font-semibold`,
                          tw`lg:(text-[3.375rem])`,
                          `color: ${getTWColorHex(colorBasedOnType)}`,
                        ]}
                      >
                        {toUsd(dueAtSigningAmount)}
                      </span>
                    </div>
                    <div
                      css={[
                        tw`text-[0.625rem] font-semibold uppercase`,
                        tw`lg:(text-sm)`,
                      ]}
                    >
                      {_("DUE AT SIGNING")}
                    </div>
                  </div>
                )}
              </div>
              {/* EXTRA CASH AMOUNT */}
              {extraCashAmount ? (
                <p
                  css={[
                    tw`block w-full font-semibold uppercase text-center mb-4`,
                    tw`lg:(mt-6 mb-0)`,
                  ]}
                >
                  {"+ $"} {toUsd(extraCashAmount)} {_(extraCashLabel)}
                </p>
              ) : null}
            </section>
          </section>
          <section
            css={[
              tw`flex flex-col gap-10`,
              tw`lg:(flex-row w-full justify-between items-end mt-6)`,
            ]}
          >
            <div css={[tw`flex flex-wrap justify-center gap-2`]}>
              <LocalInventoryLinks
                city={city}
                dealerCount={dealers?.length}
                inventoryCount={inventoryCount}
                series={name}
                inventoryLink={generateInventoryLink({
                  series: slug?.replace("hybrid", "")?.toLowerCase(),
                })}
                year={currentYear}
                series={name}
                analyticsId="offer:"
              />
            </div>
            <div css={[tw`flex justify-center gap-10`]}>
              <ShareButton text={`${name} ${cardLabel} Offer`}>
                {_("Share This Offer")}
              </ShareButton>
              {/* <div css={[tw`flex items-center`]}>
                <Icon.Heart filled color="red-400" css={[tw`w-6 mr-1`]} />
                <span css={[tw`text-sm font-semibold`]}>Save to My Offers</span>
              </div> */}
            </div>
          </section>
        </article>
        <article
          css={[
            tw`flex justify-center items-center max-w-xs w-full mx-auto mt-8`,
            tw`xl:(flex justify-center items-center max-w-xs w-full mt-0)`,
          ]}
        >
          <Link
            to={`/offers/${seriesOffersPath}`}
            css={[
              tw`flex justify-center items-center text-xl font-semibold`,
              "line-height: 1.5rem",
            ]}
            analytics-id={"series offers:offer:"}
            onClick={() =>
              trackTealEvent({
                tealium_event: "cta_click",
                coupon_module_text: `View All ${name} Offers`,
                link_href: `/offers/${seriesOffersPath}/`,
                vehicle_model: name,
                vehicle_model_truncated: name,
              })
            }
          >
            <div>
              <div css={[tw`text-sm font-book mb-3`]}>
                <span css={[tw`font-semibold`]}>{offerCount} </span>
                {offerCount > 1 || offerCount === 0
                  ? _("Offers Available")
                  : _("Offer Available")}
              </div>
              <div css={[tw`whitespace-nowrap`]}>{_("View All")}</div>
              <div css={[tw`whitespace-nowrap`]}>
                {name}
                <span css={[language == "es" && tw`lowercase`]}>
                  {" "}
                  {_("Offers")}
                </span>
              </div>
            </div>
            <Icon.Chevron
              color="red-400"
              direction="right"
              css={[tw`h-4 relative left-3 top-3`]}
            />
          </Link>
        </article>
      </div>
    </section>
  )
}

export default OfferDetailCard
